import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
export default {
  methods: {
    onChangeLocale: function onChangeLocale(command) {
      this.$i18n.locale = command;
      var message = "\u5F53\u524D\u8BED\u8A00\uFF1A".concat(this.$t('_name'), " [ ").concat(this.$i18n.locale, " ]");

      if (process.env.VUE_APP_BUILD_MODE === 'PREVIEW') {
        message = ["\u5F53\u524D\u8BED\u8A00\uFF1A".concat(this.$t('_name'), " [ ").concat(this.$i18n.locale, " ]"), "\u4EC5\u63D0\u4F9B\u5207\u6362\u529F\u80FD\uFF0C\u6CA1\u6709\u914D\u7F6E\u5177\u4F53\u7684\u8BED\u8A00\u6570\u636E ", "\u6587\u6863\u53C2\u8003\uFF1A<a class=\"el-link el-link--primary is-underline\" target=\"_blank\" href=\"https://d2.pub/zh/doc/d2-admin/locales\">\u300A\u56FD\u9645\u5316 | D2Admin\u300B</a>"].join('<br/>');
      }

      this.$notify({
        title: '语言变更',
        dangerouslyUseHTMLString: true,
        message: message
      });
    }
  }
};