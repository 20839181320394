var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-multiple-page-control-group", attrs: { flex: "" } },
    [
      _c(
        "div",
        {
          staticClass: "d2-multiple-page-control-content",
          attrs: { "flex-box": "1" },
        },
        [
          _c(
            "div",
            { staticClass: "d2-multiple-page-control-content-inner" },
            [
              _c(
                "d2-contextmenu",
                {
                  attrs: {
                    visible: _vm.contextmenuFlag,
                    x: _vm.contentmenuX,
                    y: _vm.contentmenuY,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.contextmenuFlag = $event
                    },
                  },
                },
                [
                  _c("d2-contextmenu-list", {
                    attrs: {
                      menulist:
                        _vm.tagName === "/index"
                          ? _vm.contextmenuListIndex
                          : _vm.contextmenuList,
                    },
                    on: { rowClick: _vm.contextmenuClick },
                  }),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "d2-multiple-page-control d2-multiple-page-sort",
                  attrs: { value: _vm.current, type: "card", closable: true },
                  on: {
                    "tab-click": _vm.handleClick,
                    edit: _vm.handleTabsEdit,
                  },
                  nativeOn: {
                    contextmenu: function ($event) {
                      return _vm.handleContextmenu.apply(null, arguments)
                    },
                  },
                },
                _vm._l(_vm.opened, function (page) {
                  return _c("el-tab-pane", {
                    key: page.fullPath,
                    attrs: {
                      label: page.meta.title || "未命名",
                      name: page.fullPath,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d2-multiple-page-control-btn",
          attrs: { "flex-box": "0" },
        },
        [
          _c(
            "el-dropdown",
            {
              attrs: { size: "default", "split-button": "" },
              on: {
                click: _vm.closeAll,
                command: function (command) {
                  return _vm.handleControlItemClick(command)
                },
              },
            },
            [
              _c("d2-icon", { attrs: { name: "times-circle" } }),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "left" } },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-10",
                        attrs: { name: "arrow-left" },
                      }),
                      _vm._v(" 关闭左侧 "),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "right" } },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-10",
                        attrs: { name: "arrow-right" },
                      }),
                      _vm._v(" 关闭右侧 "),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "other" } },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-10",
                        attrs: { name: "times" },
                      }),
                      _vm._v(" 关闭其它 "),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "all" } },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-10",
                        attrs: { name: "times-circle" },
                      }),
                      _vm._v(" 全部关闭 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }