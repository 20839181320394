import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import store from '@/store';
import axios from 'axios';
import { Message } from 'element-ui';
import util from '@/libs/util'; // 创建一个错误

function errorCreate(msg) {
  var error = new Error(msg);
  errorLog(error);
  throw error;
} // 记录和显示错误


function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error: error
    }
  }); // 打印到控制台

  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>');
    console.log(error);
  } // 显示提示


  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
} // 创建一个 axios 实例


var service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 5000 // 请求超时时间

}); // 请求拦截器

service.interceptors.request.use(function (config) {
  // 在请求发送之前做一些处理
  var token = util.cookies.get('token'); // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改

  config.headers['X-Token'] = token;
  var current_account_set_id = localStorage.getItem("admin_id");

  if (current_account_set_id) {
    config.headers["Current-Admin-Id"] = current_account_set_id;
  }

  return config;
}, function (error) {
  // 发送失败
  console.log(error);
  return Promise.reject(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data; // 这个状态码是和后端约定的

  var code = dataAxios.code; // 根据 code 进行判断

  if (code === undefined) {
    // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
    return dataAxios;
  } else {
    // 有 code 代表这是一个后端接口 可以进行进一步的判断
    if (code != 0) {
      //出错处理，如果要做登录处理等,都在这里进行
      Message({
        message: dataAxios.msg,
        type: 'error',
        duration: 5 * 1000
      });
    }

    return dataAxios;
    /* switch (code) {
      case 0:
        // [ 示例 ] code === 0 代表没有错误
        return dataAxios.data
      case 'xxx':
        // [ 示例 ] 其它和后台约定的 code
        errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`)
        break
      default:
        // 不是正确的 code
        errorCreate(`${dataAxios.msg}: ${response.config.url}`)
        break
    } */
  }
}, function (error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }
  }

  errorLog(error);
  return Promise.reject(error);
});
/**
 * 文件下载
 * @param config axios_config
 * @param filename 文件名
 */

service.download = function (config, filename) {
  config.responseType = "blob";
  service(config).then(function (res) {
    var blob = new Blob([res], {
      type: res.type
    });
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); //创建下载的链接

    downloadElement.href = href;
    downloadElement.download = filename; //下载后文件名

    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载

    document.body.removeChild(downloadElement); //下载完成移除元素

    window.URL.revokeObjectURL(href); //释放blob对象
  });
};
/**
 * 以新窗口的方式打开文件（例如：浏览pdf）
 * @param config
 */


service.open = function (config) {
  console.log(config);
  config.responseType = "blob";
  service(config).then(function (res) {
    var blob = new Blob([res], {
      type: res.type
    });
    var downloadElement = document.createElement('a');
    var href = window.URL.createObjectURL(blob); //创建下载的链接

    downloadElement.href = href;
    downloadElement.target = "_blank";
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载

    document.body.removeChild(downloadElement); //下载完成移除元素

    window.URL.revokeObjectURL(href); //释放blob对象
  });
};

export default service;