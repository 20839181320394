var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small" } },
    [
      _c("span", { staticClass: "btn-text" }, [
        _vm._v(
          _vm._s(_vm.user.real_name ? "你好 " + _vm.user.real_name : "未登录")
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "激活码",
            width: "600px",
            visible: _vm.activeCodeVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.activeCodeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "激活码", "label-width": "150px" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { size: "small", autocomplete: "off" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.activeCodeVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.activeCodeVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.changePwd.apply(null, arguments)
                },
              },
            },
            [
              _c("d2-icon", { staticClass: "d2-mr-5", attrs: { name: "key" } }),
              _vm._v(" 重置密码 "),
            ],
            1
          ),
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function ($event) {
                  return _vm.logOff.apply(null, arguments)
                },
              },
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" },
              }),
              _vm._v(" 注销 "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }