import "E:\\code\\wwwroot\\local.lzj\\timesharing_bespeak\\admin\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "E:\\code\\wwwroot\\local.lzj\\timesharing_bespeak\\admin\\node_modules\\core-js\\modules\\es.promise.js";
import "E:\\code\\wwwroot\\local.lzj\\timesharing_bespeak\\admin\\node_modules\\core-js\\modules\\es.object.assign.js";
import "E:\\code\\wwwroot\\local.lzj\\timesharing_bespeak\\admin\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 菜单和路由设置

import router from './router';
import menuHeader from '@/menu/header';
import menuAside from '@/menu/aside';
import { frameInRoutes } from '@/router/routes'; //vue-axios 配置

import VueAxios from 'vue-axios';
import axios from '@/plugin/axios/';
Vue.use(VueAxios, axios);
Vue.prototype.helper = import('@/libs/helper'); // 核心插件

Vue.use(d2Admin);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes); // 设置顶栏菜单

    this.$store.commit('d2admin/menu/headerSet', menuHeader); // 设置侧边栏菜单

    this.$store.commit('d2admin/menu/asideSet', menuAside); // 初始化菜单搜索功能

    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');