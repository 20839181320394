var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d2-layout-header-aside-group",
      class: { grayMode: _vm.grayActive },
      style: _vm.styleLayoutMainGroup,
    },
    [
      _c("div", { staticClass: "d2-layout-header-aside-mask" }),
      _c(
        "div",
        {
          staticClass: "d2-layout-header-aside-content",
          attrs: { flex: "dir:top" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-theme-header",
              style: {
                opacity: this.searchActive ? 0.5 : 1,
              },
              attrs: { "flex-box": "0", flex: "" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "logo-group",
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth,
                  },
                  attrs: { to: "/index", "flex-box": "0" },
                },
                [_c("d2-header-title")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "toggle-aside-btn",
                  attrs: { "flex-box": "0" },
                  on: { click: _vm.handleToggleAside },
                },
                [_c("d2-icon", { attrs: { name: "bars" } })],
                1
              ),
              _c("div", {
                staticClass: "top-nav",
                staticStyle: { "padding-top": "18px" },
              }),
              _c("span", {
                staticStyle: {
                  display: "inline-block",
                  "padding-top": "25px",
                  "font-size": "14px",
                },
              }),
              _c("d2-menu-header", { attrs: { "flex-box": "1" } }),
              _c(
                "div",
                { staticClass: "d2-header-right", attrs: { "flex-box": "0" } },
                [
                  _c("d2-header-search", {
                    on: { click: _vm.handleSearchClick },
                  }),
                  _c("d2-header-fullscreen"),
                  _c("d2-header-theme"),
                  _c("d2-header-size"),
                  _c("d2-header-color"),
                  _c("d2-header-user"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d2-theme-container",
              attrs: { "flex-box": "1", flex: "" },
            },
            [
              _c(
                "div",
                {
                  ref: "aside",
                  staticClass: "d2-theme-container-aside",
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth,
                    opacity: this.searchActive ? 0.5 : 1,
                  },
                  attrs: { "flex-box": "0" },
                },
                [_c("d2-menu-side")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d2-theme-container-main",
                  attrs: { "flex-box": "1", flex: "" },
                },
                [
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "" },
                          },
                          [
                            _c("d2-panel-search", {
                              ref: "panelSearch",
                              on: { close: _vm.searchPanelClose },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    !_vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "dir:top" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-header",
                                attrs: { "flex-box": "0" },
                              },
                              [_c("d2-tabs")],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-body",
                                attrs: { "flex-box": "1" },
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: _vm.transitionActive
                                        ? "fade-transverse"
                                        : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "keep-alive",
                                      { attrs: { include: _vm.keepAlive } },
                                      [_c("router-view")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }