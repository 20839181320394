var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "container_main pt10",
      },
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div a1_div",
                  style: {
                    height: (_vm.info_height * 23) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("快捷入口"),
                  ]),
                  _c("div", { staticClass: "item_list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/order?type=0")
                          },
                        },
                      },
                      [_vm._v("个人预约")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/order?type=1")
                          },
                        },
                      },
                      [_vm._v("团队预约")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/user")
                          },
                        },
                      },
                      [_vm._v("用户管理")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/place")
                          },
                        },
                      },
                      [_vm._v("场馆配置")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/risk_area")
                          },
                        },
                      },
                      [_vm._v("风险数据")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item",
                        on: {
                          click: function ($event) {
                            return _vm.openPage("/swiper")
                          },
                        },
                      },
                      [_vm._v("轮播管理")]
                    ),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div a2_div",
                  style: {
                    height: (_vm.info_height * 23) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("今日游客(人)"),
                  ]),
                  _c("div", { staticClass: "line_1" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.today.person)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_2" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.today.person_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                      _c("div", { staticClass: "item_3" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.today.team)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_4" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.today.team_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "line_2" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [_vm._v(" 散客 ")]),
                      _c("div", { staticClass: "item_2" }, [_vm._v(" 团队 ")]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div a3_div",
                  style: {
                    height: (_vm.info_height * 23) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("未来7日游客(人)"),
                  ]),
                  _c("div", { staticClass: "line_1" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.week.person)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_2" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.week.person_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                      _c("div", { staticClass: "item_3" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.week.team)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_4" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.week.team_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "line_2" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [_vm._v(" 散客 ")]),
                      _c("div", { staticClass: "item_2" }, [_vm._v(" 团队 ")]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div a4_div",
                  style: {
                    height: (_vm.info_height * 23) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("未来30日游客(人)"),
                  ]),
                  _c("div", { staticClass: "line_1" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.month.person)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_2" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.month.person_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                      _c("div", { staticClass: "item_3" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.month.team)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("全部")]),
                      ]),
                      _c("div", { staticClass: "item_4" }, [
                        _c("span", { staticClass: "text_1" }, [
                          _vm._v(_vm._s(_vm.user_num_data.month.team_party)),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "text_2" }, [_vm._v("党员")]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "line_2" }, [
                    _c("div", { staticClass: "item_list" }, [
                      _c("div", { staticClass: "item_1" }, [_vm._v(" 散客 ")]),
                      _c("div", { staticClass: "item_2" }, [_vm._v(" 团队 ")]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div b1_div",
                  style: { height: (_vm.info_height * 75) / 100 + "px" },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("游客区域分析"),
                  ]),
                  _c("div", { staticClass: "date_text" }, [_vm._v("近7日")]),
                  _c("v-chart", {
                    staticClass: "b1_chart",
                    attrs: { option: _vm.b1_option },
                  }),
                  _c(
                    "table",
                    {
                      staticClass: "b2_table",
                      attrs: {
                        border: "0",
                        cellpadding: "0",
                        cellspacing: "0",
                        width: "100%",
                      },
                    },
                    [
                      _c("tr", [
                        _c("th", [_vm._v("省市")]),
                        _c("th", [_vm._v("地区")]),
                        _c("th", [_vm._v("游客数量")]),
                        _c("th", [_vm._v("地区风险等级")]),
                      ]),
                      _vm._l(_vm.b1_table_data, function (item) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(item.province_name))]),
                          _c("td", [_vm._v(_vm._s(item.city_name))]),
                          _c("td", [_vm._v(_vm._s(item.num))]),
                          _c(
                            "td",
                            [
                              item.level == 0
                                ? [
                                    _c("span", {
                                      staticClass: "circle circle_low",
                                    }),
                                    _vm._v("低风险"),
                                  ]
                                : _vm._e(),
                              item.level == 1
                                ? [
                                    _c("span", {
                                      staticClass: "circle circle_medium",
                                    }),
                                    _vm._v("中风险"),
                                  ]
                                : _vm._e(),
                              item.level == 2
                                ? [
                                    _c("span", {
                                      staticClass: "circle circle_high",
                                    }),
                                    _vm._v("高风险"),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div b2_div",
                  style: {
                    height: (_vm.info_height * 30) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("18岁以下，广东省，韶关市"),
                  ]),
                  _c("div", { staticClass: "date_text" }, [_vm._v("近7日")]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "50px",
                        "font-size": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v(_vm._s(_vm.user_age_18)),
                          ]),
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v(_vm._s(_vm.gds)),
                          ]),
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v(_vm._s(_vm.sgs)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v("18岁以下"),
                          ]),
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v("广东省"),
                          ]),
                          _c("div", { staticStyle: { margin: "20px" } }, [
                            _vm._v("韶关市"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div b3_div",
                  style: {
                    height: (_vm.info_height * 30) / 100 + "px",
                    marginBottom: (_vm.info_height * 2) / 100 + "px",
                  },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("最新预约"),
                  ]),
                  _c("div", { staticClass: "select_div" }, [
                    _c(
                      "div",
                      {
                        staticClass: "item item_1",
                        class: { select_item: _vm.b3_status == 0 },
                        on: {
                          click: function ($event) {
                            _vm.b3_status = 0
                          },
                        },
                      },
                      [_vm._v("个人")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "item item_2",
                        class: { select_item: _vm.b3_status == 1 },
                        on: {
                          click: function ($event) {
                            _vm.b3_status = 1
                          },
                        },
                      },
                      [_vm._v("团队")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "link_div" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openPage("/order")
                            },
                          },
                        },
                        [_vm._v("查看更多")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "item_list" },
                    [
                      _vm.b3_status == 0
                        ? _c(
                            "el-timeline",
                            { attrs: { reverse: true } },
                            _vm._l(_vm.b3_user_data, function (item, index) {
                              return _c(
                                "el-timeline-item",
                                {
                                  key: index,
                                  attrs: {
                                    timestamp: item.create_time,
                                    type: "primary",
                                    color: "#2E51FF",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text_1" }, [
                                    _vm._v(
                                      "游客：" +
                                        _vm._s(item.name) +
                                        " 人数：" +
                                        _vm._s(item.num)
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.b3_status == 1
                        ? _c(
                            "el-timeline",
                            { attrs: { reverse: true } },
                            _vm._l(_vm.b3_team_data, function (item, index) {
                              return _c(
                                "el-timeline-item",
                                {
                                  key: index,
                                  attrs: {
                                    timestamp: item.create_time,
                                    type: "primary",
                                    color: "#2E51FF",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "text_1" }, [
                                    _vm._v(
                                      "团队：" +
                                        _vm._s(item.name) +
                                        " 人数：" +
                                        _vm._s(item.num)
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c(
                "div",
                {
                  staticClass: "info_div c1_div",
                  style: { height: (_vm.info_height * 43) / 100 + "px" },
                },
                [
                  _c("div", { staticClass: "title_div title_text" }, [
                    _vm._v("游客年龄分析"),
                  ]),
                  _c("div", { staticClass: "date_text" }, [_vm._v("近7日")]),
                  _c("v-chart", {
                    staticClass: "c1_chart",
                    attrs: { option: _vm.c1_option },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }